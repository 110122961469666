import classes from './Filters.module.scss'
import { AnimatePresence } from 'framer-motion'
import animations from '../../../../other/animations'
import Box from '../../../ui/Box/Box'
import Switcher from '../../..//ui/Switcher/Switcher'

const Filters = ({
  filters,
  onFiltersVisibleChange,
  onSortTypeChange,
  onIsPricesHighlightedChange,
  onIsOnlyDuplicateChange,
  onIsPhotosBluredChange,
  className,
  ...rest
}) => {
  return <>
    <AnimatePresence>
      {filters.isVisible && <Box
        className={`${classes.wrapper} ${className}`}
        title='Фильтры'
        titleSize={18}
        isCloseBtnExists={true}
        onClick={(e) => e.stopPropagation()}
        onClose={() => onFiltersVisibleChange()}
        {...animations.filters}
        {...rest}
      >
        <select
          className={classes.sortSelect}
          value={filters.sortType}
          onChange={(e) => onSortTypeChange(e.target.value)}
        >
          <option value='byIndex' defaultValue={true}>по порядку</option>
          <option value='byPrice'>по цене</option>
          <option disabled>-</option>
          <option value='byAgeAsc'>по возрасту △</option>
          <option value='byAgeDesc'>по возрасту ▽</option>
          <option disabled>-</option>
          <option value='byHeightAsc'>по росту △</option>
          <option value='byHeightDesc'>по росту ▽</option>
          <option disabled>-</option>
          <option value='byWeightAsc'>по весу △</option>
          <option value='byWeightDesc'>по весу ▽</option>
        </select>

        <Switcher
          className={classes.isPricesHighlighted}
          isChecked={filters.isPricesHighlighted}
          onSwitch={onIsPricesHighlightedChange}
        >выделять цены</Switcher>

        <Switcher
          className={classes.isOnlyDuplicate}
          isChecked={filters.isOnlyDuplicate}
          onSwitch={onIsOnlyDuplicateChange}
        >только совпадения</Switcher>

        <Switcher
          className={classes.isPhotosBlured}
          isChecked={filters.isPhotosBlured}
          onSwitch={onIsPhotosBluredChange}
        >размывать фото</Switcher>
      </Box>}
    </AnimatePresence>
  </>
}

export default Filters
