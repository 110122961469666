import classes from './Overlay.module.scss'
import { motion } from 'framer-motion'

const Overlay = ({ children, onClick = () => null, bg = null, className, ...rest }) => {
  return <motion.div
    className={`${classes.overlay} ${className}`}
    onClick={onClick}
    style={{ background: bg ?? 'rgba(0, 0, 0, 0.9)' }}
    {...rest}
  >{children}</motion.div>
}

export default Overlay
