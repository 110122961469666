export default function extractProfiles(html, dosugUrl) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const profiles = []

  const profileElements = doc.querySelectorAll('.grids.nthchild')

  profileElements.forEach((profileElement, index) => {
    const nameAgeElement = profileElement.querySelector('a[href^="/girls/"]')
    const heightWeightElement = Array.from(profileElement.querySelectorAll('p')).find(p => p.textContent.includes('Рост:'))
    const imgElement = profileElement.querySelector('img')
    const phoneElement = profileElement.querySelector('.grid-6 a[href^="/girls/"]')
    const descriptionElement = profileElement.querySelector('.grid-6 p')
    const priceTableElement = profileElement.querySelector('.main-table')
    const preferencesElement = profileElement.querySelector('.grid-5.hidden-phone')
    const expressElement = profileElement.querySelector('.existInExClass')
    const linkElement = profileElement.querySelector('a[href^="/girls/"]')

    const nameAgeText = nameAgeElement ? nameAgeElement.textContent.trim() : ''
    const [name, age] = nameAgeText.split(' - ')

    const profile = {
        index,
        name: name || '',
        age: age ? parseInt(age.replace(' лет', ''), 10) : null,
        height: heightWeightElement ? parseInt(heightWeightElement.textContent.match(/Рост: (\d+)/)[1], 10) : null,
        weight: heightWeightElement ? parseInt(heightWeightElement.textContent.match(/Вес: (\d+)/)[1], 10) : null,
        image: imgElement ? dosugUrl + imgElement.src.replace(/.*\/uploads/, '/uploads') : '',
        phone: phoneElement ? phoneElement.textContent.trim() : '',
        description: descriptionElement ? descriptionElement.textContent.trim() : '',
        prices: [],
        preferences: [],
        express: expressElement ? expressElement.style.display !== 'none' : false,
        link: linkElement ? linkElement.href.match(/\/girls\/\d+/) : ''
    }

    if (priceTableElement) {
        const rows = priceTableElement.querySelectorAll('tr')
        rows.forEach(row => {
            const cells = row.querySelectorAll('td')
            if (cells.length === 3) {
                profile.prices.push({
                    apartments: cells[1].textContent.trim(),
                    outcall: cells[2].textContent.trim()
                })
            }
        })
    }

    if (preferencesElement) {
        const preferenceLists = preferencesElement.querySelectorAll('ul')
        preferenceLists.forEach(list => {
            const items = list.querySelectorAll('li')
            items.forEach(item => {
                profile.preferences.push(item.textContent.trim())
            })
        })
    }

    profiles.push(profile)
  })

  return profiles.slice(0, -1)
}
