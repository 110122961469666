const env = {
  // urls: {
  //   ORIGINAL: 'https://dsgomsk.space/',
  //   PROXY: 'https://corsproxy-9hr4.onrender.com/proxy?url=',
  //   SOURCE: 'https://berry.dosug-55omsk.com/',
  //   NEW: 'new' // ждут звонка
  // },
  urls: {
    ORIGINAL: 'http://185.177.219.142/',
    PROXY: 'api?url=',
    // PROXY: 'http://185.177.219.142/api?url=',
    SOURCE: 'https://berry.dosug-55omsk.com/',
    NEW: 'new' // ждут звонка
  },
}

export default Object.freeze(env)
