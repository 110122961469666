import classes from './Box.module.scss'
import { motion } from 'framer-motion'

const Box = ({
  children,
  title = null,
  isCloseBtnExists = false,
  onClose = () => null,
  className,
  titleClass,
  titleSize,
  splitterClass,
  contentClass,
  ...rest
}) => {
  return <>
    <motion.div className={`${classes.wrapper} ${className}`} {...rest}>
      {isCloseBtnExists && <svg
        className={classes.closeBtn}
        onClick={onClose}
        xmlns='http://www.w3.org/2000/svg'
        width='24px'
        height='24px'
        viewBox='0 -960 960 960'
        fill='#e8eaed'
      >
        <path d='m258.42-218.69-38.92-39.73L440.27-480 219.5-701.58l38.92-39.73L480-519.73l221.58-221.58 38.92 39.73L519.73-480 740.5-258.42l-38.92 39.73L480-440.27 258.42-218.69Z'/>
      </svg>}

      {title && <>
        <h1 className={`${classes.title} ${titleClass}`} style={{ fontSize: titleSize ? +titleSize : '' }}>{title}</h1>
        <div className={`${classes.splitter} ${splitterClass}`} />
      </>}
        
      {children}
    </motion.div>
  </>
}

export default Box
