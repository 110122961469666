const animations = {
  profiles: {
    initial: { opacity: 0 },
    animate: i => ({ opacity: 1, transition: { delay: i * 0.060 } }),
  },
  profile: {
    initial: { opacity: 0, scale: 0.97 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.97 }
  },
  overlay: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  },
  overlayWhithoutInitial: {
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  },
  title: {
    animate: {
      color: ['#ffc6ff', '#fdffb6', '#9bf6ff', '#ffc6ff'],
      transition: {
        duration: 16,
        repeat: Infinity,
        ease: 'linear'
      }
    }
  },
  filters: {
    initial: { top: -80, opacity: 0 },
    animate: { top: 8, opacity: 1, transition: { duration: .150 } },
    exit: { top: -80, opacity: 0, transition: { duration: .150 } },
  },
  slider: {
    img: {
      initial: { opacity: 0, x: 100 },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: -100 },
      transition: { duration: 0.150 }
    },
    prevBtn: {
      initial: { x: 0 },
      whileHover: { x: -4 },
      transition: { duration: 0.1 }
    },
    nextBtn: {
      initial: { x: 0 },
      whileHover: { x: 4 },
      transition: { duration: 0.1 }
    }
  },
  helloBox: {
    initial: { opacity: 0, scale: 0.97 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.97 }
  }
}

export default animations
