import classes from './HelloBox.module.scss'
import Overlay from '../../../generic/Overlay/Overlay'
import Box from '../../../ui/Box/Box'
import animations from '../../../../other/animations'
import env from '../../../../other/environment'

const HelloBox = ({ onOkClick }) => {
  return <>
    <Overlay {...animations.overlay} bg='rgba(0, 0, 0, .9)'>
      <Box
        className={classes.wrapper}
        title={'Привет 🤝'}
        {...animations.helloBox}
      >
        <div className={classes.description}>
          <p>Это мини-сайтец с анкетами проституток Омска, который парсит страницы с <a href={env.urls.SOURCE}>dosug-55omsk.com</a>, а конкретно категорию «ждут звонка».</p>
          <p>Работоспособность зависит не только от меня, но и от типов которые управляют оригинальным сайтом, ведь они могут что-то поменять, и тогда здесь всё будет работать некорректно, если вообще будет. Оригиналу регулярно блокируют домены, и им приходится менять их, соответственно, мне тоже приходится менять ссылку, чтобы всё работало.</p>
          <p>Некоторые регают несколько анкет на один и тот же номер. Если они совпадают, то справа от номера будет указан индекс анкеты с таким же номером.</p>
          <p>Анкету можно добавить в избранное: сохраняются последние четыре цифры номера телефона, так что если есть несколько анкет на одном номере, то они все пойдут в избранное. Все избранные будут в начале списка, если не будешь чистить историю браузера, то порядок будет сохраняться.</p>
          <p>При первом посещении фотки на главной размыты, сделал только для себя, убрать можно в меню фильтров, нажав на кнопку справа-сверху. Если ты решил зайти через режим "инкогнито", то приготовься, что все фильтры будут слетать, а это сообщение будет выползать при каждом новом заходе в режим.</p>
        </div>

        <button className={classes.okBtn} onClick={onOkClick}>ok</button>
      </Box>
    </Overlay>
  </>
}

export default HelloBox
