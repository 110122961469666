import classes from './Comments.module.scss'
import Box from '../../../../ui/Box/Box'

const Comments = ({ children, profileName = '' }) => {
  return <>
    <div className={classes.wrapper}>
      <Box title={<span>Отзывы <span className={classes.commentsAmount}>{children.length}</span></span>} titleSize={16}>
        <ul className={classes.list}>
          {children.map((comment, i) => {
            const name = comment.date.match(/(?<=от\s).*/)[0].trim()
            const date = comment.date.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)[0].trim()

            return <li key={i} className={classes.item}>
              <p className={`${classes.name} ${profileName.trim() === name && classes.highlighted}`}>{name}</p>
              <p className={classes.date}>{date}</p>
              <p className={classes.text}>{comment.text}</p>
            </li>
          })}
        </ul>
      </Box>
    </div>
  </>
}

export default Comments
