import classes from './CircleBtn.module.scss'
import { motion } from 'framer-motion'

const CircleBtn = ({
  children,
  onClick = () => null,
  className,
  ...rest
}) => {
  return <>
    <motion.div
      onClick={onClick}
      className={`${classes.button} ${className}`}
      {...rest}
    >{children}</motion.div>
  </>
}

export default CircleBtn
// svg должна быть 24px
