import classes from './Switcher.module.scss'
import { motion } from 'framer-motion'

const Switcher = ({
  className,
  children,
  isChecked = false,
  onSwitch = () => null,
  ...rest
}) => {
  function onChange(e) {
    onSwitch(!isChecked)
  }
  
  return <>
    <motion.div
      className={`${classes.wrapper} ${className}`}
      onClick={onChange}
      {...rest}
    >
      <label className={classes.switcher}>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={() => null}
          onClick={(e) => e.stopPropagation()}
        />
        <span className={classes.slider} />
      </label>
      <span className={classes.caption}>{children}</span>
    </motion.div>
  </>
}

export default Switcher
