export default function extractProfile(html, dosugUrl) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const nameAgeElement = doc.querySelector('h2')
  const imgElements = doc.querySelectorAll('img')
  const phoneElement = doc.querySelector('h3')
  const descriptionElement = doc.querySelector('.grid-6 p[style*="font-size: 120%"]')
  const preferencesElements = doc.querySelectorAll('.grid-item.grid-5.green ul')
  const expressElement = doc.querySelector('.existInExClass')
  const commentElements = doc.querySelectorAll('.inc_commentdate')

  const nameAgeText = nameAgeElement ? nameAgeElement.textContent.trim() : ''
  const [name, age] = nameAgeText.split(' - ')

  const profile = {
    name: name.trim() || '',
    age: age ? parseInt(age.replace(' лет', ''), 10) : null,
    height: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Рост:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    weight: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Вес:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    district: [...doc.querySelectorAll('li')].find(li => li.textContent.includes('Район:'))?.textContent.split(': ')[1] || '',
    hairColor: [...doc.querySelectorAll('li')].find(li => li.textContent.includes('Цвет волос:'))?.textContent.split(': ')[1] || '',
    breastSize: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Грудь:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    photos: Array.from(imgElements)
      .map(img => dosugUrl + img.src.replace(/.*\/uploads/, '/uploads'))
      .filter(src => src.includes('/list')),
    phone: phoneElement ? phoneElement.textContent.trim() : '',
    description: descriptionElement ? descriptionElement.textContent.trim() : '',
    prices: {
      hour: parseInt([...doc.querySelectorAll('tr')].find(tr => tr.textContent.includes('Час:'))?.querySelector('td:nth-child(2)')?.textContent.trim() || '0', 10),
      outcallHour: [...doc.querySelectorAll('tr')].find(tr => tr.textContent.includes('Час:'))?.querySelector('td:nth-child(3)')?.textContent.trim() === '-' ? null : parseInt([...doc.querySelectorAll('tr')].find(tr => tr.textContent.includes('Час:'))?.querySelector('td:nth-child(3)')?.textContent.trim() || '0', 10)
    },
    preferences: [],
    express: expressElement ? expressElement.style.display !== 'none' : false,
    comments: []
  }

  preferencesElements.forEach(list => {
    const items = list.querySelectorAll('li')
    items.forEach(item => {
      const preferenceText = item.textContent.trim()
      const [preference, extraCharge] = preferenceText.split('+')
      profile.preferences.push({
        preference: preference.trim(),
        extraCharge: extraCharge ? parseInt(extraCharge, 10) : null
      })
    })
  })

  commentElements.forEach(commentElement => {
    const date = commentElement.textContent.trim()
    const name = commentElement.querySelector('.inc_commentname')?.textContent.trim()
    const text = commentElement.nextElementSibling.textContent.trim()
    profile.comments.push({ date, name, text })
  })

  return profile
}
