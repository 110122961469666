import classes from './Loader.module.scss'

const Loader = ({ color, caption = null, className, ...rest }) => {
  return <>
    <div className={classes.wrapper}>
      <div className={`${classes.loader} ${className}`} {...rest}></div>
      {caption && <div className={classes.caption}>{caption}</div>}
    </div>
  </>
}

export default Loader
